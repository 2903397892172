<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-end mb-0">
          <h4 class="mr-8">New users</h4>
          <p class="hp-badge-text">for the last 14 days</p>
        </div>

        <div class="overflow-hidden">
          <apexchart
            type="bar"
            height="250"
            legend="legend"
            :options="newUsersOptions"
            :series="newUsersSeries"
          ></apexchart>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
import adminwidgetsServices from "../../../../../services/adminwidgets.services";
import axios from "axios";

export default {
  data() {
    return {
      totalusers: 0,
      newUsersSeries: [
        {
          name: "New users",
          data: [],
        },
      ],
      newUsersOptions: {
        chart: {
          fontFamily: "Manrope, sans-serif",
          type: "bar",

          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },

        dataLabels: {
          enabled: false,
        },

        grid: {
          borderColor: "#DFE6E9",
          row: {
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 2,
            columnWidth: "45%",
            endingShape: "rounded",
          },
          colors: {
            backgroundBarColors: ["#0063F7", "#00F7BF"],
          },
        },

        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        xaxis: {
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },

          tickPlacement: "between",
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "14px",
            },
          },
          categories: [],
        },
        legend: {
          horizontalAlign: "right",
          offsetX: 40,
          position: "top",
          markers: {
            radius: 12,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "14px",
            },
            formatter: (value) => {
              return value;
            },
          },

          min: 0,
          max: 100,
          tickAmount: 4,
        },
      },
    };
  },
  mounted() {
    adminwidgetsServices.getNewUsers().then((response) => {
      var dataCount = [];
      for (var i in response) {
        var count = response[i].count;
        var date = response[i].date;
        dataCount.push(count);
        this.newUsersOptions.xaxis.categories.push(date);
      }

      dataCount.reverse();
      this.newUsersOptions.xaxis.categories.reverse();

      this.newUsersSeries = [
        {
          name: "New users",
          data: dataCount,
        },
      ];
    });
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  methods: {
    largeScale() {
      for (var i = 0; i < 100; i++) {
        var random = Math.floor(Math.random() * 10000000);

        var calldata = {
          stepOne: {
            discordid: "618228507000045599",
            discordusername: "Sacul",
            discorddiscriminator: "0001",
            discordavatar:
              "https://cdn.discordapp.com/avatars/618228507000045599/da11f22c653df071bc1cd30fac2d39c0.png",
            guildroles: [
              "804604205435584512",
              "898587992627761152",
              "950787614288527392",
              "1004430940698849320",
              "966714575720443914",
              "927960118266589195",
              "919715498411847761",
              "871843769933242388",
              "950786578190590042",
              "804604253117087774",
            ],
          },
          stepTwo: { welcomeStatus: "checked" },
          stepThree: {
            firstName: "Test",
            lastName: "User",
            userName: "testuser-" + random,
            phoneNumber: "test",
            country: "BE",
            email: "test@test.com",
            password: "test",
            isAdmin: false,
            onboardStatus: "submitted",
            isOnboarded: false,
            userUUID: "b077d90d-c86d-4645-b5af-b85e220bfa8c",
            createdAt: "02/09/2022 00:26:28",
          },
          stepFour: { gamesInterestedIn: ["sunflowerland"] },
          stepFive: {
            walletMetamask: "test",
            walletRonin: "",
            walletSolana: "",
            walletWax: "",
          },
          stepSix: { reason: "test" },
          stepSeven: {},
          stepEight: {
            wheredidyouhearaboutus: "other",
            nftGamesBefore: "yes",
            scholarshipBefore: "yes",
            scholarshipBeforeName: "test",
            gameKnowledge: "5",
            referralDiscordUsername: "Sacul#0001",
          },
        };

        axios
          .post("https://api-unp.sacul.cloud/auth/signup", calldata)
          .then((res) => {
            //console.log(res.data);
          })
          .catch((err) => {
            //console.log(err.response.data);
          });
      }
    },
  },
};
</script>
