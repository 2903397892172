import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://api-unp.sacul.cloud/NeK9NFKk/admin/widget/";
const SECURE_KEY = "o0XO*vG6^amgP^LjxzGOUwJTrnM4Ejfw76hiXHoE1w#GbpUqpM";

class AdminWidgetsServices {
  async getNewUsers() {
    var params = {
      secureKey: SECURE_KEY,
    };
    try {
      const response = await axios.post(API_URL + "newUsers14days", params, {
        headers: authHeader(),
      });
      return response.data.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async getHeaderWidgets() {
    var params = {
      secureKey: SECURE_KEY,
    };
    try {
      const response = await axios.post(
        API_URL + "headerDashboardWidgets",
        params,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (error) {
      return "NOT_OK";
    }
  }
}

export default new AdminWidgetsServices();
