<template>
  <b-row>
    <b-col class="col-sm-3 col-12 mb-12">
      <b-card>
        <b-row>
          <b-col class="hp-flex-none w-auto">
            <b-avatar class="bg-primary-4 hp-bg-color-dark-primary" size="48px">
              <i
                class="iconly-Light-People text-primary hp-text-color-dark-primary-2"
                style="font-size: 24px"
              ></i>
            </b-avatar>
          </b-col>

          <b-col class="pl-0">
            <h3 class="mb-4 mt-8">
              {{ totalUsers
              }}<span class="hp-badge-text ml-8 text-primary"></span>
            </h3>

            <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
              Total Users
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-sm-3 col-12 mb-12">
      <b-card>
        <b-row>
          <b-col class="hp-flex-none w-auto">
            <b-avatar class="bg-primary-4 hp-bg-color-dark-primary" size="48px">
              <i
                class="iconly-Light-People text-primary hp-text-color-dark-primary-2"
                style="font-size: 24px"
              ></i>
            </b-avatar>
          </b-col>

          <b-col class="pl-0">
            <h3 class="mb-4 mt-8">
              {{ onboardedUsers
              }}<span class="hp-badge-text ml-8 text-primary"></span>
            </h3>

            <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
              Onboarded Users
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-sm-3 col-12 mb-12">
      <b-card>
        <b-row>
          <b-col class="hp-flex-none w-auto">
            <b-avatar class="bg-primary-4 hp-bg-color-dark-primary" size="48px">
              <i
                class="iconly-Light-People text-primary hp-text-color-dark-primary-2"
                style="font-size: 24px"
              ></i>
            </b-avatar>
          </b-col>

          <b-col class="pl-0">
            <h3 class="mb-4 mt-8">
              {{ newApplications
              }}<span class="hp-badge-text ml-8 text-primary"></span>
            </h3>

            <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
              New Applications
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-sm-3 col-12 mb-12">
      <b-card>
        <b-row>
          <b-col class="hp-flex-none w-auto">
            <b-avatar class="bg-primary-4 hp-bg-color-dark-primary" size="48px">
              <i
                class="ri-cup-fill text-primary hp-text-color-dark-primary-2"
                style="font-size: 24px"
              ></i>
            </b-avatar>
          </b-col>

          <b-col class="pl-0">
            <h3 class="mb-4 mt-8">
              243<span class="hp-badge-text ml-8 text-primary"></span>
            </h3>

            <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
              Coffee
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col class="col-sm-3 col-12 mb-12 mt-12">
      <b-card class="hp-card-0 hp-eCommerceCardOne">
        <b-row>
          <b-col cols="12" class="mb-8">
            <b-row align-v="start" align-h="between">
              <b-col>
                <h5 class="mb-0">Interested In</h5>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <p
                  class="hp-badge-text text-black-80 hp-text-color-dark-30 mb-0"
                >
                  Based on Total Users
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <b-row
              align-v="center"
              align-h="between"
              class="mt-12"
              :key="index"
              v-for="(item, index) in mostInterestedIn"
            >
              <b-col cols="6">
                <div class="d-flex align-items-center">
                  <p class="hp-p1-body mb-0">{{ item.title }}</p>
                </div>
              </b-col>

              <b-col cols="6">
                <div
                  class="d-flex align-items-center justify-content-end position-relative"
                >
                  <p class="hp-badge-text mb-0 mr-8">{{ item.value }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-sm-3 col-12 mb-12 mt-12">
      <b-card class="hp-card-0 hp-eCommerceCardOne">
        <b-row>
          <b-col cols="12" class="mb-8">
            <b-row align-v="start" align-h="between">
              <b-col>
                <h5 class="mb-0">Onboarded For</h5>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <p
                  class="hp-badge-text text-black-80 hp-text-color-dark-30 mb-0"
                >
                  Based on Onboarded Users
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <b-row
              align-v="center"
              align-h="between"
              class="mt-12"
              :key="index"
              v-for="(item, index) in mostOnboardedFor"
            >
              <b-col cols="6">
                <div class="d-flex align-items-center">
                  <p class="hp-p1-body mb-0">{{ item.title }}</p>
                </div>
              </b-col>

              <b-col cols="6">
                <div
                  class="d-flex align-items-center justify-content-end position-relative"
                >
                  <p class="hp-badge-text mb-0 mr-8">{{ item.value }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-sm-6 col-12 mb-12 mt-12">
      <b-card class="hp-card-3 hp-eCommerceCardOne">
        <b-row>
          <b-col cols="12" class="mb-8">
            <b-row align-v="start" align-h="between">
              <b-col>
                <h5 class="mb-0">Countries</h5>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <p
                  class="hp-badge-text text-black-80 hp-text-color-dark-30 mb-0"
                >
                  Based on Total Users
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" style="max-height: 297px" class="overflow-auto">
            <b-row
              align-v="center"
              align-h="between"
              class="mt-12 scrollable"
              :key="index"
              v-for="(item, index) in countries"
            >
              <b-col cols="6">
                <div class="d-flex align-items-center">
                  <div class="mr-8">
                    <img :src="item.countryFlag" :alt="item.countryName" />
                  </div>

                  <p class="hp-p1-body mb-0">{{ item.countryName }}</p>
                </div>
              </b-col>

              <b-col cols="6">
                <div
                  class="d-flex align-items-center justify-content-end position-relative"
                >
                  <p class="hp-badge-text mb-0 mr-8">{{ item.amount }}</p>

                  <!--<apexchart
                    :type="item.chart.type"
                    :width="item.chart.width"
                    :height="item.chart.height"
                    :options="item.chart.options"
                    :series="item.chart.series"
                  ></apexchart>-->
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";
import adminwidgetsServices from "../../../services/adminwidgets.services";
import countryList from "./countryList.json";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  data() {
    return {
      totalUsers: 0,
      onboardedUsers: 0,
      newApplications: 0,
      coffee: 0,
      mostInterestedIn: [],
      mostOnboardedFor: [],
      countries: [],
      gamesData: [],
    };
  },
  async mounted() {
    await adminwidgetsServices.getHeaderWidgets().then((response) => {
      this.totalUsers = response.data.totalUsers;
      this.onboardedUsers = response.data.onboardedUsers;
      this.newApplications = response.data.newApplications;
      var InterestedIn = response.data.mostInterestedIn;
      var OnboardedFor = response.data.mostOnboardedFor;
      var Countries = response.data.countries;

      for (var i in InterestedIn) {
        this.mostInterestedIn.push({
          title: InterestedIn[i].title,
          value: InterestedIn[i].value,
        });
      }
      for (var i in OnboardedFor) {
        this.mostOnboardedFor.push({
          title: OnboardedFor[i].title,
          value: OnboardedFor[i].value,
        });
      }
      // Go through each of the countries and sort them by the number of users
      for (var i in Countries) {
        if (i.length > 2) {
          continue;
        }

        this.countries.push({
          countryCode: i,
          countryName: countryList[i],
          countryFlag:
            "https://flagcdn.com/24x18/" + i.toLocaleLowerCase() + ".png",
          amount: Countries[i],
        });
      }
      this.countries.sort(function (a, b) {
        return b.amount - a.amount;
      });
    });

    this.mostInterestedIn.sort((a, b) => b.value - a.value);
    this.mostOnboardedFor.sort((a, b) => b.value - a.value);
  },
};
</script>
