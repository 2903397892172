<template>
  <b-row>
    <b-col cols="12">
      <b-row class="mb-32">
        <b-col cols="12">
          <h3>Welcome back, {{ username }} 👋</h3>
          <p class="hp-p1-body mb-0">I hope you're having a great day!</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <top-widgets />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-12 mb-32">
          <new-users-card />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BAlert } from "bootstrap-vue";

import NewUsersCard from "./members-save/widgets/NewUsers.vue";
import TopWidgets from "./TopWidgets.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    NewUsersCard,
    TopWidgets,
  },
  data() {
    return {
      username: this.$store.state.auth.user.username,
    };
  },
};
</script>
